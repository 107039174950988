import React, { Fragment } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { Menu, Dropdown } from 'semantic-ui-react';
import * as Styles from '../styles';

interface IProp {
    pathName:string,
    children:JSX.Element
}

const NoRole = ({pathName, children}:IProp) => {
    return (
        <Fragment>
        <Menu.Item name="West Ryde" as={NavLink} to="/west-ryde"
            style={pathName === '/west-ryde' ? Styles.activeMenuItemStyle : Styles.menuItemStyle} />
        <Menu.Item name="City" as={NavLink} to="/service-city"
            style={pathName === '/service-city' ? Styles.activeMenuItemStyle : Styles.menuItemStyle} />
        <Menu.Item name="목요 찬양" as={NavLink} to="/service-the-heart"
            style={pathName === '/service-the-heart' ? Styles.activeMenuItemStyle : Styles.menuItemStyle} />
        <Menu.Item name="주보" style={(pathName === '/westryde_bulletin' ||
            pathName === '/city_bulletin') ? Styles.activeMenuItemStyle : Styles.menuItemStyle}>
            <Dropdown pointing="top left" text='주보'>
                <Dropdown.Menu>
                    <Dropdown.Item as={Link} to="/westryde_bulletin" text="West ryde 주보"
                        style={pathName === '/westryde_bulletin' ? Styles.activeSubMenuItemStyle : Styles.subMenuItemStyle} />
                    <Dropdown.Item as={Link} to="/city_bulletin" text="City 주보"
                        style={pathName === '/city_bulletin' ? Styles.activeSubMenuItemStyle : Styles.subMenuItemStyle} />
                </Dropdown.Menu>
            </Dropdown>
        </Menu.Item>
        <Menu.Item name="교회 소개" style={(pathName === '/church' ||
            pathName === '/pastor' ||
            pathName === '/contacts') ? Styles.activeMenuItemStyle : Styles.menuItemStyle} >
            <Dropdown pointing="top left" text='교회 소개'>
                <Dropdown.Menu>
                    <Dropdown.Item as={Link} to="/church" text="교회 소개"
                        style={pathName === '/church' ? Styles.activeSubMenuItemStyle : Styles.subMenuItemStyle} />
                    {/* <Dropdown.Item as={Link} to="/pastor" text="교역자 소개"
                        style={pathName === '/pastor' ? Styles.activeSubMenuItemStyle : Styles.subMenuItemStyle} /> */}
                    <Dropdown.Item as={Link} to="/contacts" text="Contacts"
                        style={pathName === '/contacts' ? Styles.activeSubMenuItemStyle : Styles.subMenuItemStyle} />
                </Dropdown.Menu>
            </Dropdown>
        </Menu.Item>
        <Menu.Item name="교회 영상" style={(pathName === '/video_event' ||
            pathName === '/video_praise') ? Styles.activeMenuItemStyle : Styles.menuItemStyle} >
            <Dropdown pointing="top left" text='교회 영상'>
                <Dropdown.Menu>
                    <Dropdown.Item as={Link} to="/video_event" text="행사"
                        style={pathName === '/video_event' ? Styles.activeSubMenuItemStyle : Styles.subMenuItemStyle} />
                    <Dropdown.Item as={Link} to="/video_praise" text="찬양"
                        style={pathName === '/video_praise' ? Styles.activeSubMenuItemStyle : Styles.subMenuItemStyle} />
                </Dropdown.Menu>
            </Dropdown>
        </Menu.Item>
        {/* <Menu.Item name="교회 앨범" as={NavLink} to="/galleries"
            style={pathName === '/galleries' ? Styles.activeMenuItemStyle : Styles.menuItemStyle} /> */}
        {children}
    </Fragment>
    );
};

export default NoRole;
